<template>
	<el-dialog title="" v-mode="visible" :before-close="beforeClose" width="70%">
        <div>
		<el-table :data="GetAdVersionList" >
			<el-table-column label="版本名称" prop="versionName"></el-table-column>
			<el-table-column label="文件" prop="sample"></el-table-column>
			<el-table-column label="上传人" prop="name"></el-table-column>
			<el-table-column label="上传时间" prop="rowTime"></el-table-column>
			<el-table-column label="操作" align="center">
				<template v-slot="scope">
					<el-button type="text" @click="onDownItem(scope.row)">下载</el-button>
					<el-button type="text" @click="onDeleteItem(scope.row)">删除</el-button>										
				</template>
			</el-table-column>
		</el-table>
        </div>
		<div style="display:flex;justify-content:center;margin-top:10px;">
			<el-button @click="beforeClose">返回</el-button>
		</div>
	</el-dialog>
</template>

<script>
import CommonTable from "@/components/CommonTable";
import CommonTableColumn from "@/components/CommonTableColumn";
import {DelAdVersion} from "@/api/operate";
import {getToken} from "@/utils/auth";
import {mapGetters} from "vuex";
import {getFileUrl} from "@/api/file";
// import {dealDeleteOperation} from "@/utils/tools";

export default {
		name: "PlayResultDialog",
		components: {CommonTableColumn, CommonTable},
		props: ['visible', 'project'],
		computed: {...mapGetters(['GetAdVersionList'])},

		data() {
				return {
				}
		}, 
        watch: {
				visible(newValue) {
						// if (newValue) {
						// 		this.$store.dispatch('operate/GetAdVersionList', this.project.projectId)
						// }
				}
		},
		methods: {

				beforeClose() {
					this.$emit('close', false);
				},
				async onDeleteItem(item) {
					console.log(item)
                    this.$confirm('确认删除'+item.versionName+', 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(async () => {
                        await DelAdVersion({userId: getToken(), versionId: item.versionId});
                        await this.$store.dispatch('operate/GetAdVersionList', {projectId:this.project.projectId});
                        this.$message({
                            type: 'success',
                            message: '删除成功!'
                        });
                    }).catch(() => {
                        // this.$message({
                        //     type: 'info',
                        //     message: '已取消删除'
                        // });          
                    });

						// await dealDeleteOperation({deletePromise:DelAdVersion({userId: getToken(), versionId: item.versionId})})
						
				},
				onDownItem(item) {
					console.log('aa',item,getFileUrl({name: item.sample}));
					document.location.href = getFileUrl({name: item.sample})
				}
		}
}
</script>

<style scoped lang="scss">
.look-contract-dialog-container {
		.el-form-item {
				/*width: 50%;*/
		}
}
</style>