<!--代理管理中的合同弹窗-->
<template>
		<el-dialog title="上传广告版本" v-mode="visible" :before-close="beforeClose" width="50%">
				<el-form :model="form">
					<el-form-item label="版本名称">
						<el-input v-model="form.versionName" style="width:213px;" placeholder="输入版本名称" class="handle-input mr10"></el-input>
					</el-form-item>
					<my-upload-file-form-item label="版本素材" :limit="1" @change="getFileArr" v-model="form.fileArray"/>
				</el-form>
				<div style="display:flex;justify-content:center;">
					<el-button @click="beforeClose">返回</el-button>
					<el-button @click="onConfirm" type="primary">确定</el-button>
				</div>
		</el-dialog>
</template>

<script>
import { SaveAdVersion} from "@/api/operate";
import {getToken} from "@/utils/auth";
import {mapGetters} from "vuex";
import MyUploadFileFormItem from "@/components/MyUploadFileFormItem";


export default {
		name: "UploadDialog",
		components: {MyUploadFileFormItem},
		props: ['visible', 'project'],
		computed: {...mapGetters([])},

		data() {
				return {
						form: {versionName: '', fileArray: []}
				}
		}, 
        watch: {
				visible(newValue) {
                    console.log('visible',visible)
						if (newValue) {
							// this.$store.dispatch('contract/contractReportTypeList',)
                            
						}
				}
		},
		methods: {
				beforeClose() {
						// this.$emit('update:visible', false);
                    this.$emit('close', false);
				},
                init(){
                    this.form.versionName='';
                    this.form.fileArray=[];
                },
                
                async onConfirm() {
						console.log(this.form);
						const {versionName, fileArray: [reportFile]} = this.form;
						if (!versionName || !reportFile) {
							this.$message.error('请填写完整');
							return;
						}
						await SaveAdVersion({
								userId: getToken(),
								projectId: this.project.projectId,
                                versionName,
                                versionId:this.project.versionId,
								sample:reportFile.name,							
						});
						this.$message.success('保存成功');
						this.beforeClose();
						// await this.$store.dispatch('operate/GetProjectSmartList',);
                        this.$parent.onQuery();
				},

                getFileArr(item){
                    if (item.length || item.length==0){
                        this.form.fileArray=item;
                    }else{
                        // console.log('bb')
                    }
                    console.log('item111',item,item.length)
                }
		}
}
</script>

<style scoped lang="scss">
.look-contract-dialog-container {
		.el-form-item {
				/*width: 50%;*/
		}
}
</style>