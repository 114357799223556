<template>
	<el-dialog title="" v-mode="visible" :before-close="beforeClose" width="70%">
        <div style="margin-top:-55px;">
                            <div style="display:flex;justify-content:space-between">
                               
                                <div style="display:flex;">
                                    <i class="el-icon-s-flag grid-num"></i> 
                                    <div class="grid-num">综合投放效果<span style="color:red;margin-left:20px;font-weight: bold;"></span></div>
                                </div>

                            </div>
                            <div>
                                <el-row :gutter="40" class="mgb20">
                                    <el-col :span="24">
                                        <el-card shadow="hover" :body-style="{ padding: '0px' }" >
                                            <div class="grid-content grid-con-4" style="height:160px;">                                                
                                                <div class="grid-cont-right" style="display:flex;border:0px;align-items: center;">
                                                    <div style="width:100%;display: flex;justify-content: center;">
                                                        <div><i class="el-icon-money grid-con-icon"></i></div>
                                                        <div style="display:flex;flex-direction:column;justify-content: center;align-items:center;">
                                                            <div class="grid-num">￥{{result.cost}}元</div>
                                                            <div>费用</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </el-card>
                                    </el-col>

                                    <el-row style="margin-top:20px;width: 100%;">
                                    <el-col :span="12">
                                        <el-card shadow="hover" :body-style="{ padding: '0px' }">
                                            <div class="grid-content grid-con-3">
                                                <i class="el-icon-s-goods grid-con-icon" ></i>
                                                <div class="grid-cont-right" >
                                                    
                                                    <div class="grid-num-small">{{result.frequency}}次</div>
                                                    <div>暴露频次</div>
                                                </div>
                                            </div>
                                        </el-card>
                                    </el-col>
                                   
                                    <el-col :span="12">
                                        <el-card shadow="hover" :body-style="{ padding: '0px' }">
                                            <div class="grid-content grid-con-2">
                                                <i class="el-icon-message-solid grid-con-icon"></i>
                                                <div class="grid-cont-right">
                                                    <div class="grid-num-small">{{result.CPM}}元</div>
                                                    <div>CPM</div>
                                                </div>
                                            </div>
                                        </el-card>
                                    </el-col>
                                    </el-row>                                
                                </el-row>
                            </div>                    
                        </div>
                        <template #footer>
                            <span class="dialog-footer">                                
                                <el-button @click="beforeClose">关闭</el-button>                                
                            </span>
                        </template>
	</el-dialog>
</template>

<script>


export default {
		name: "PlayResultDialog",
		components: {},
		props: ['result'],
		// computed: {...mapGetters(['GetAdVersionList'])},

		data() {
				return {
				}
		}, 
        watch: {
				visible(newValue) {

				}
		},
		methods: {

			beforeClose() {
						// this.$emit('update:visible', false);
                console.log('guan')
                this.$emit('close', false);
			},
		}
}
</script>

<style scoped lang="scss">
.transition-box {
    margin-bottom: 10px;
    width: 200px;
    height: 100px;
    border-radius: 4px;
    background-color: #409EFF;
    text-align: center;
    color: #fff;
    padding: 40px 20px;
    box-sizing: border-box;
    margin-right: 20px;
  }

.schart-box {
    display: inline-block;
    margin: 20px;
}
.schart {
    width: 600px;
    height: 400px;
}
.content-title {
    clear: both;
    font-weight: 400;
    line-height: 50px;
    margin: 10px 0;
    font-size: 22px;
    color: #1f2f3d;
}


.grid-content {
    display: flex;
    align-items: center;
    height: 120px;
}

.grid-subcontent {
    display: flex;
    align-items: center;
    height: 50px;
}

.grid-cont-right {
    flex: 1;
    text-align: center;
    font-size: 14px;
    color: #999;
}

.grid-num {
    font-size: 15px;
    /* font-weight: bold; */
}

.grid-num-small {
    font-size: 16px;
    font-weight: bold;
}

.grid-subnum {
    font-size: 20px;
    font-weight: bold;
}

.grid-con-icon {
    font-size: 50px;
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 100px;
    color: #fff;
    border-radius: 50%;
    margin-left: 80px;
}
.grid-subcon-icon{
    font-size: 30px;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    color: #fff;
}

.grid-con-0 .grid-subcon-icon {
    background: rgb(184, 223, 223);
}

.grid-con-0 .grid-num {
    color: rgb(184, 223, 223);
}

.grid-con-1 .grid-con-icon {
    background: rgb(45, 140, 240);
}

.grid-con-1 .grid-num {
    color: rgb(45, 140, 240);
}

.grid-con-2 .grid-con-icon {
    background: rgb(100, 213, 114);
}

.grid-con-2 .grid-num {
    color: rgb(45, 140, 240);
}

.grid-con-3 .grid-con-icon {
    background: rgb(242, 94, 67);
}

.grid-con-3 .grid-num {
    color: rgb(242, 94, 67);
}

.grid-con-4 .grid-con-icon {
    background: rgb(132, 94, 67);
}

.grid-con-4 .grid-num {
    color: rgb(132, 94, 67);
}

.grid-con-5 .grid-con-icon {
    background: rgb(66, 94, 67);
}

.grid-con-5 .grid-num {
    color: rgb(66, 94, 67);
}

.grid-con-6 .grid-con-icon {
    background: rgb(66, 136, 67);
}

.grid-con-6 .grid-num {
    color: rgb(66, 136, 67);
}

.clearfix{
    margin-top: 20px;
}
</style>